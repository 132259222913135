<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('plant_check') ? '暂无数据' : '暂无数据查看权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @loadEnter="loadEnter"
      @loadExpend="loadExpend"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="140" :rules="rules">
        <Row>
          <Col span="11">
            <FormItem label="企业名称" prop="planterName">
              <Input
                clearable
                placeholder="请输入企业名称"
                v-model="form.planterName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="统一社会信用代码" prop="creditCode">
              <Input
                clearable
                placeholder="请输入统一社会信用代码"
                v-model="form.creditCode"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="联系人" prop="name">
              <Input
                clearable
                placeholder="请输入联系人名称"
                v-model="form.name"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="电话号码" prop="tel">
              <Input
                clearable
                placeholder="请输入电话号码"
                v-model="form.tel"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="企业类型" prop="enterpriseType">
              <Select
                clearable
                placeholder="请输入企业类型"
                v-model="form.enterpriseType"
              >
                <Option
                  v-for="(item, index) in companyData"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="土地规模">
              <Input>
                <span slot="append">㎡</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="所属区域" prop="regionCode">
              <Cascader
                :data="area"
                v-model="form.regionCode"
                change-on-select
                :load-data="loadData"
              ></Cascader>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="详细地址" prop="plantingAddress">
              <Input
                placeholder="请输入种植地址"
                v-model="form.plantingAddress"
              >
                <span
                  slot="append"
                  class="iconfont"
                  style="cursor: pointer"
                  @click="mapModelShow"
                  >&#xe648;</span
                >
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="营业期限" prop="businessTerm">
              <Input
                clearable
                placeholder="请输入营业期限"
                v-model="form.businessTerm"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="经营范围" prop="businessScope">
              <Input
                v-model="form.businessScope"
                placeholder="请输入经营范围"
                clearable
                type="textarea"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="种植类型" prop="typeInfo">
              <Select
                v-model="form.typeInfo"
                multiple
                placeholder="请选择种植类型"
              >
                <Option
                  v-for="(item, index) in catData"
                  :key="index"
                  :value="item.id + '/' + item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="图片上传">
              <upload-image
                :max="1"
                :clear-flag="!modal.show"
                v-model="form.logo"
              ></upload-image>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <Modal v-model="mapModal" fullscreen>
      <search-map
        v-if="mapModal"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModal = false)">提交</Button>
        <Button @click="() => (mapModal = false)">取消</Button>
      </p>
    </Modal>

    <!-- 详情 -->
    <plant-detail
      v-model="listDetail.show"
      :listDetailInfo="listDetail.info"
      :listDetailData="detailData"
      :plantingInfoData="plantingInfo"
    ></plant-detail>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import searchMap from "@/components/searchMap";
import pictureView from "@/components/picture_view";
import CU from "@/common/util";
import plantDetail from "./plantDetail.vue";
export default {
  name: "",
  components: {
    searchMap,
    pictureView,
    plantDetail,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "LOGO",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              if (!row.logo) return <span>--</span>;
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%;cursor:pointer"
                    src={this.getImgUrl(row.servicePath, row.logo)}
                    onClick={() => this.lookPicture(row.servicePath + row.logo)}
                  />
                </div>
              );
            },
          },
          {
            title: "企业名称",
            tooltip: true,
            key: "planterName",
            minWidth: 150,
          },
          {
            title: "企业类型",
            width: 100,
            align: "center",
            key: "enterpriseTypeName",
          },
          {
            title: "统一社会信用代码",
            align: "center",
            width: 180,
            key: "creditCode",
          },
          // {
          //   title: "所属行业",
          //   width: 140,
          //   align: "center",
          //   key: "industry",
          // },
          {
            title: "种植类型",
            width: 150,
            align: "center",
            tooltip: true,
            key: "typeName",
          },
          {
            title: "经营范围",
            align: "center",
            width: 150,
            key: "businessScope",
          },
          {
            title: "营业期限",
            minWidth: 100,
            key: "businessTerm",
          },
          {
            title: "区域",
            width: 180,
            key: "regionCodeName",
          },
          {
            title: "联系人",
            key: "name",
            width: 100,
          },
          {
            title: "电话",
            width: 130,
            key: "tel",
          },
          {
            title: "地址",
            tooltip: true,
            width: 200,
            key: "plantingAddress",
          },
          // {
          //   title: "创建时间",
          //   width: 180,
          //   key: "createTime",
          // },
          {
            title: "操作",
            width: 150,
            fixed: "right",
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("plant_edit") && (
                    <a
                      style="margin-right:20px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("plant_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                  {this.CA("plant_detail") && (
                    <a
                      style="margin-left:10px"
                      on-click={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        tel: "",
        plantingAddress: "",
        type: [],
        typeName: [],
        typeInfo: [],
        category: "",
        planterName: "",
        thirdLongLat: "",
        //社会统一信用代码
        creditCode: "",
        //企业类型
        enterpriseType: "",
        //营业期限
        businessTerm: "",
        //所属行业
        industry: "种植业",
        //省市区编码
        regionCode: [],
        //经营范围
        businessScope: "",
        logo: "",
      },
      rules: {
        // name: [{ required: true, message: "请填写联系人名称" }],
        planterName: [{ required: true, message: "请填写企业名称" }],
        tel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        plantingAddress: [{ required: true, message: "请填写种植地址" }],
        // typeInfo: [{ required: true, message: "请选择种植类型" }],
        // creditCode: [{ required: true, message: "请输入社会统一信用代码" }],
        // enterpriseType: [{ required: true, message: "请选择企业类型" }],
        // businessTerm: [{ required: true, message: "请输入营业期限" }],
        // industry: [{ required: true, message: "请输入所属行业" }],
        regionCode: [
          // { required: true, type: "array", message: "请选择省市区" },
        ],
        // businessScope: [{ required: true, message: "请输入经营范围" }],
      },
      catData: [],
      currentData: null,
      mapModal: false,
      companyData: [],
      area: [],

      picture: {
        show: false,
        src: "",
      },
      //详情
      listDetail: {
        show: false,
        info: {},
        data: {},
      },
      detailData: [],
      plantingInfo: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增种植户",
            ca: "plant_add",
          },
          loadModel: {
            url: "https://castoff.hogdata.cn/app/种植企业信息.xlsx",
          },
          loadEnter: {
            loadName: "导入",
            ca: "plants_import",
          },
          loadExpend: {
            loadExpendName: "导出",
            ca: "",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "企业名称",
              component: "input",
              field: "planterName",
              defaultValue: "",
            },
            {
              conditionName: "企业类型",
              component: "select",
              field: "enterpriseType",
              defaultValue: "",
              data: this.companyData,
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "种植区域",
              component: "cascader",
              field: "regionCodes",
              defaultValue: [],
              data: this.area,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: "联系人",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "联系电话",
              component: "input",
              field: "tel",
              defaultValue: "",
            },
            {
              conditionName: "详细地址",
              component: "input",
              field: "plantingAddress",
              defaultValue: "",
            },
            {
              conditionName: "经营范围",
              component: "input",
              field: "businessScope",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        formdata.append("category", this.user.category + 1);
        this.$post(this.$api.PLANT_MANAGE.UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.$Message.success("导入成功");
          this.getList();
        });
      };
      input.click();
    },
    dealMapData(mapData) {
      if (!this.form.plantingAddress)
        this.form.plantingAddress = mapData.address;
      this.form.thirdLongLat = mapData.thirdLongLat;
    },
    mapModelShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModal = true;
    },
    getList() {
      if (!this.CA("plant_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PLANT_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getArea() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "500153",
      }).then((res) => {
        this.area = res.map((item) => {
          let obj = {
            ...item,
            value: item.id,
            label: item.name,
            children: [],
            loading: false,
          };
          return obj;
        });
      });
    },
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      }).then((res) => {
        if (!res || res.length === 0) {
          item.children = [
            {
              value: "无",
              label: "无",
              disabled: true,
            },
          ];
        } else {
          res.forEach((item) => {
            item.value = item.id;
            item.label = item.name;
            if (item.hasChild == "true") {
              item.children = [];
              item.loading = false;
            }
          });
          item.children = res;
        }
        item.loading = false;
        callback();
      });
    },
    assemble(n) {
      return new Promise((resolve) => {
        if (n.length > 0) {
          n.forEach((item, key) => {
            this.$post(this.$api.AREA_MANAGE.LIST, {
              parentId: item,
            }).then((res) => {
              this.judge(this.area, res, item);
              if (key == n.length - 1) {
                resolve();
              }
            });
          });
        } else {
          resolve();
        }
      });
    },
    judge(area, res, item) {
      area.forEach((el) => {
        if (el.id == item) {
          el.children = res.map((it) => {
            let obj = {
              ...it,
              value: it.id,
              label: it.name,
              children: [],
              loading: false,
            };
            if (it.levelType == "3") {
              delete obj.children;
              delete obj.loading;
            }
            return obj;
          });
          return;
        }
        if (el.children && el.children.length > 0) {
          this.judge(el.children, res, item);
        }
      });
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "1",
      }).then((res) => {
        this.catData = res.list;
      });
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "203",
      }).then((res) => {
        this.companyData = res.list;
      });
    },
    search(value) {
      let n;
      for (let i = 0; i < value.regionCodes.length; i++) {
        n = value.regionCodes[value.regionCodes.length - 1];
      }
      let obj = {
        ...value,
        // regionCode: value.regionCodes.join(","),
        regionCode: n,
      };
      this.search_data = obj;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "新增种植户",
        submitLoading: false,
      };
    },
    edit(row) {
      let n = row.regionCode.split(",");
      n.pop();
      this.assemble(n).then(() => {
        for (let key in this.form) {
          this.form[key] = row[key];
        }
        this.form.typeInfo = [];
        this.form.type = this.form.type.split(",");
        this.form.typeName = this.form.typeName.split(",");
        this.form.regionCode = row.regionCode.split(",");
        for (let i = 0; i < this.form.type.length; i++) {
          let str = this.form.type[i] + "/" + this.form.typeName[i];
          this.form.typeInfo.push(str);
        }
        this.form.logo = this.getImgUrl(row.servicePath, row.logo);
        this.modal = {
          show: true,
          title: "编辑种植户",
          submitLoading: false,
        };
      });
    },
    delete(id) {
      this.$post(this.$api.PLANT_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        let params = { ...this.form };
        let url;
        if (params.id) {
          url = this.$api.PLANT_MANAGE.UPDATE;
        } else {
          url = this.$api.PLANT_MANAGE.ADD;
        }
        params.type = [];
        params.typeName = [];
        params.category = this.user.category + 1;
        this.form.typeInfo.forEach((item) => {
          params.type.push(item.split("/")[0]);
          params.typeName.push(item.split("/")[1]);
        });
        params.type = params.type.toString();
        params.typeName = params.typeName.toString();
        params.regionCode = this.form.regionCode.join(",");
        delete params.typeInfo;
        let index = this.form.logo ? this.form.logo.indexOf("/", 10) : -1;
        if (index !== -1) {
          params.logo = this.form.logo.substring(index + 1);
        }
        if (!res) return;
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? "修改成功！" : "增加成功！");
          this.getList();
          this.modal.show = false;
        });
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        tel: "",
        plantingAddress: "",
        type: [],
        typeName: [],
        typeInfo: [],
        planterName: "",
        creditCode: "",
        enterpriseType: "",
        businessTerm: "",
        industry: "种植业",
        regionCode: [],
        businessScope: "",
        logo: "",
      };
      this.$refs.form.resetFields();
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },

    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      columns.pop();
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.title == "LOGO") {
            return this.getImgUrl(row.servicePath, row.logo);
          }
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "种植户企业");
    },
    //详情
    checkInfo(row) {
      this.$post(this.$api.PLANT_MANAGE.INFO, {
        userId: row.userId,
        companyNo: row.companyNo,
      }).then((res) => {
        this.detailData = res.farmList;
        this.plantingInfo = res.plantingInfo;
        this.listDetail = {
          show: true,
          info: row,
        };
      });
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
    this.getCompanyCategory();
    this.getArea();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>